import React from 'react';
import Header from './Header';
import GetMeal from './GetMeal';

const HomePage = () => {

 return (
    <div>
      <Header />
      <GetMeal/>
    </div>
 );
};

export default HomePage;
