import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from "react-router-dom";

function Header() {
 const nav = useNavigate();
 const [anchorElUser, setAnchorElUser] = React.useState(null);

 const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
 };
 const handleLogout = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  nav("/login"); 
  handleCloseUserMenu();
 };
 
 const handleCloseUserMenu = () => {
    setAnchorElUser(null);
 };

 return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          ADIVA Team Software Quality and Reliability Project
        </Typography>
        <Box sx={{ flexGrow: 0 }}>
          <IconButton onClick={handleOpenUserMenu}>
            <Avatar alt="User Avatar" src="/static/images/avatar/2.jpg" />
          </IconButton>
          <Menu
            anchorEl={anchorElUser}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
 );
}

export default Header;
